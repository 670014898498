
var referer = document.referrer;
referer.replace(".html", "");
// var previous_page = referer.split("/");
// var startform = false;
// var language = "en";
var full_url = window.location.href;
var device = "medium";
var screen_resolution = window.screen.width + "x" + window.screen.height;
// var screen_size = window.innerWidth + "x" + window.innerHeight;
var loading_time = "";
var today = new Date();
var time = today.toLocaleTimeString().replace("AM", "").replace("PM", "");
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();
today = yyyy + "-" + mm + "-" + dd;
var time_stamp = today + " " + time;
// var dt = new Date(new Date().toString().split("GMT")[0] + " UTC").toISOString();
var date = new Date();
var time_stamp2 = date.toISOString();
var platform = "Helios|" + time_stamp2;
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    device = "small";
}
var before_loadtime = new Date().getTime();
window.onload = Pageloadtime;
function Pageloadtime() {
    var aftr_loadtime = new Date().getTime();
    // Time calculating in seconds
    var pgloadtime = (aftr_loadtime - before_loadtime) / 1000;
    loading_time = pgloadtime.toFixed(1) + " seconds";
    //document.getElementById("loadtime").innerHTML = "Pgae load time is <font color='red'><b>" + pgloadtime + "</b></font> Seconds";
}



export default function adobeAnalytics(currency, vehiclename, page_title, model, vehicleid, language, event_happened, accessories = '', digits_18 = '', reset_form = '', event_126 = '', prop_44 = '', events = '', all ="", eVar14 ="" ) {
    var s = window.s;
    // console.log('=========', s)
    s.account = "nissanaeazprod"; //account

    s.mcorgid = "EBFA64D6543CEC7A0A4C98A2@AdobeOrg"
    s.visitor = Visitor.getInstance("EBFA64D6543CEC7A0A4C98A2@AdobeOrg")

    const vehicleName = vehiclename !== '' ? `|${vehiclename}` : '';
    s.currencyCode = 'AED'; // Country Code
    s.pageName = "showroom touchscreen"; //pagename                       // previously -> virtual showroom
    s.eVar1 = "showroom touchscreen" + vehicleName;                      // previously -> virtual showroom
    s.prop1 = "showroom touchscreen" + vehicleName;                      // previously -> virtual showroom
    s.eVar2 = "showroom touchscreen" + vehicleName  //sub section        // previously -> virtual showroom
    s.prop2 = "showroom touchscreen" + vehicleName    //sub section      // previously -> virtual showroom
    s.eVar3 = "showroom touchscreen" + vehicleName   //Sub sub section   // previously -> virtual showroom
    s.prop3 = "showroom touchscreen" + vehicleName  //Sub sub section    // previously -> virtual showroom
    // s.eVar4 = category; //category eg: PASSENGER CARS
    // s.prop4 = category; //category eg: PASSENGER CARS
    s.eVar5 = model; //model eg: MAXIMA
    s.prop5 = model; //model eg: MAXIMA
    s.eVar6 = vehicleid; //vehicleid eg: A36 (graden code)
    s.prop6 = vehicleid; //vehicleid eg: A36 (graden code)
    s.eVar8 = language; //Language
    s.prop8 = language; //Language
    s.eVar9 = "AE-AZ"; //Country
    s.prop9 = "AE-AZ"; //Country
    s.eVar10 = "nissan"; //Brand
    s.prop10 = "nissan"; //Brand
    s.eVar12 = "previous-page"; //$scope.getPreviousPage(); //Brand (edited)
    s.eVar16 = reset_form; //$scope.getPreviousPage(); //Brand (edited)
    s.eVar18 = accessories;
    s.eVar26 = event_happened;// 26 event (like where user select any color need to show it in the )
    s.eVar27 = all;
    s.eVar29 = "showroom touchscreen"; //previously -> virtual showroom v2
    s.eVar32 = digits_18; // 18 digits number
    s.eVar35 = "logout";
    s.events = events;

    s.eVar40 = loading_time; //pre loading time
    s.prop40 = loading_time; //pre loading time
    s.eVar44 = device + "|" + screen_resolution; //device & screen resolution
    s.prop44 = prop_44;
    s.eVar45 = device; //screen size
    s.prop52 = s.marketingCloudVisitorID; //visitor ID
    s.eVar54 = full_url; //component id
    s.prop54 = full_url; //component id
    s.eVar61 = platform + "|" + language; //[platform]
    s.eVar61 = "showroom touchscreen" + "|" + language; //[platform]
    s.eVar63 = time_stamp; //date and time
    // s.eVar126 = event_126; // note what event happend
    s.eVar14 = eVar14;

    s.ta();
    }
