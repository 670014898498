import Vue from 'vue'
import Router from 'vue-router'
import Login from '../view/login.vue'
import LandingMain from '../view/landingMain.vue'
import Vehicles from '../view/vehicles.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: Login
    },
    {
      path: '/',
      component: Vehicles
    },
    {
      path: '/vehicle/:modelName',
      name: 'vehicleModel',
      component: LandingMain
    },
  ]
})